<template>
  <div
    class="h100 d-flex flex-column mloyalty-panel-burger-body ml-preview-page"
  >
    <div class="flex-grow-1" v-if="sertCardInfo">
      <div class="row px-8 pt-4">
        <div class="col-12 py-0 pt-2">
          <div class="ml-title1-24-32-600 pb-9">Баланс сертификата</div>
        </div>
        <div class="col-12 py-0">
          <div class="ml-title2-40-32-600">
            {{ sertCardInfo.Sertbalance }} ₽
          </div>
        </div>
        <div class="col-12">
          <div class="ml-title3-14-24">{{ today }}</div>
        </div>
      </div>
      <div class="ml-preview-container">
        <div class="ml-pervie-card-wrapper">
          <div class="mloyalty-preview-cert-body mt-9 pb-8">
            <div class="col-12 pa-0 text-center">
              <span
                style="position:relative; top:-14px; padding: 0 10px; background: white"
                class="ml-title3-14-24"
                >СЕРТИФИКАТ</span
              >
            </div>
            <div
              class="col-12 text-center d-flex flex-column align-items-center"
            >
              <img
                v-if="logo"
                :src="logo"
                alt=""
                class="mb-6 ml-owner-top-image"
              />
              <div class="mloyalty-preview-title">
                Сертификат на {{ sertCardInfo.Nominal }} ₽
              </div>
              <div class="ml-preview-page_expiration mb-3">
                Действует до {{ expDate }}
              </div>
              <div
                class="ml-preview-page_congratulation ml-preview-page_congratulation__mt26"
              >
                {{ sertCardInfo.SertCongratulation }}
              </div>
            </div>
            <div class="col-12 d-flex flex-column align-items-center pt-7">
              <v-img
                :aspect-ratio="16 / 11"
                class="ml-img-rounded mb-10 mt-2 ml-preview-page_certificate-image"
                :src="sertCardInfo.SertImage"
              >
                <div class="ml-basket-price-label2">
                  {{ sertCardInfo.Nominal }} ₽
                </div>
              </v-img>
              <barcode
                :value="sertCardCode"
                ref="barcode"
                :lineColor="'black'"
                :text="' '"
                :width="4"
              >
                Show this if the rendering fails.
              </barcode>
              <div class="ml-preview-page_card-number mb-3">
                Номер карты: {{ sertCardCode }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapState } from 'vuex'
import ownerTypes from '@/store/owner/types'
import VueBarcode from 'vue-barcode'

export default {
  components: {
    barcode: VueBarcode
  },
  data: () => ({
    sertCardInfo: null
  }),
  computed: {
    ...mapState({
      sertSeller: state => state.app?.config?.SertSeller
    }),
    today() {
      const date = format(new Date(), 'dd.MM.yyyy')
      return `По состоянию на ${date}`
    },
    logo() {
      return this.sertSeller.find(item => item.Name === 'logo')?.Value
    },
    expDate() {
      const date = new Date(this.sertCardInfo?.SertExpDate)
      return format(date, 'dd.MM.yyyy')
    },
    sertCardCode() {
      return this.$route.params?.sertCard
    }
  },
  methods: {
    ...mapActions('owner', [ownerTypes.GET_SERT_CARD_INFO_ACTION])
  },
  async created() {
    const response = await this[ownerTypes.GET_SERT_CARD_INFO_ACTION]({
      sertCard: this.sertCardCode
    })

    this.sertCardInfo = response?.data
  }
}
</script>
